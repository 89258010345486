<template lang="pug">
BaseGroup(
	v-for="(method, index) in map"
	:key="index"
)
	.method
		div
			p
				strong {{ method.fishingMethod.name }}
			p
				strong  От:&nbsp;
				span {{ formattedDateTime(method.dateStart*1000).split(' ')[0] }}
			p
				strong До:&nbsp;
				span {{ formattedDateTime(method.dateEnd*1000).split(' ')[0]  }}
		.flex.flex--fdc(v-if="isAdmin")
			BaseButton(view="danger" @click="$emit('delete', index)") Удалить
</template>
<script>
import BaseButton from '../ui/BaseButton'
import BaseGroup from '../ui/BaseGroup'
import formattedDateTime from '@/utils/formattedDateTime'

export default {
	name: 'ListShips',
	components: { BaseGroup, BaseButton },
	props: {
		map: {
			type: Array,
			required: true
		},
		isAdmin: {
			type: Boolean,
			required: true
		}
	},
	emits: ['show', 'delete'],
	setup: () => {
		return {
			formattedDateTime
		}
	}
}
</script>

<style lang="scss">
.method {
	@include until-mobile {
		p {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			width: 66vw;
			display: block;
		}
	}
	@include until-desktop {
		display: grid;
		grid-gap: 8px;
	}
	@include for-desktop {
		display: flex;
		justify-content: space-between;
		align-items: center;

		p {
			width: 300px;
		}
	}
}
</style>
